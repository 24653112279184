








import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../lib/abpbase";
import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
import LookUp from "@/store/entities/lookup";
import CompanyKeyWordLookUpRequest from "@/store/entities/ordercontext/company-keyword-lookup-request";

@Component
export default class RemoteSelect extends AbpBase {
    @Prop() value: Object
    @Prop() callBack: (keyWordLookUpRequest: KeyWordLookUpRequest) => any;
    @Prop() code: string;
    private dataList: Array<LookUp> = new Array<LookUp>();
    private isLoading: boolean = false;

    get inputValue() {
        return this.value;
    }

    set inputValue(newValue) {
        this.$emit('input', newValue);
    }

    changed(ev) {
        this.$emit('change', ev);
    }

    async loadData(query: string) {
        if (query !== null && query.length >= 1) {
            this.isLoading = true;
            if (this.code) {
                this.dataList = await this.callBack(new CompanyKeyWordLookUpRequest(query, this.value as any, this.code));
            } else {
                this.dataList = await this.callBack(new KeyWordLookUpRequest(query, this.value as any));
            }
            this.isLoading = false;
        } else {
            this.dataList = [];
            this.isLoading = false;
        }
    }
}
