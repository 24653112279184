






















import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import SortingModel from "@/store/entities/sortingmodel";
import CompanyActionForm from "@/views/setting/companycontext/companyaction/companyaction-form.vue";

class PageActionRequest extends PageRequest {
    companyId: number;
}

@Component({components: {CompanyActionForm}})
export default class CompanyAction extends AbpBase {
    @Prop({default: 0}) companyId: number;

    //filters
    pageRequest: PageActionRequest = new PageActionRequest();

    createModalShow: boolean = false;
    editModalShow: boolean = false;

    async search() {
        await this.pageChange(1);
    }

    async changeSort(data) {
        if (this.pageRequest.sorting == null) {
            this.pageRequest.sorting = [];
        }
        let item = this.pageRequest.sorting.find(x => x.fieldName === data.key)
        if (item == null) {
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pageRequest.sorting.push(sortingModel)
        } else {
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getPage()
    }

    get list() {
        return this.$store.state.companyAction.list;
    };

    get loading() {
        return this.$store.state.companyAction.loading;
    }

    async create() {
        this.$store.commit('companyAction/setCompanyId', this.companyId);
        this.createModalShow = true;
    }

    async pageChange(page: number) {
        this.$store.commit('companyAction/setCurrentPage', page);
        await this.getPage();
    }

    async pageSizeChange(pageSize: number) {
        this.$store.commit('companyAction/setPageSize', pageSize);
        await this.getPage();
    }

    async getPage() {

        this.pageRequest.maxResultCount = this.pageSize;
        this.pageRequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'companyAction/getAll',
            data: this.pageRequest
        })
    }

    get pageSize() {
        return this.$store.state.address.pageSize;
    }

    get totalCount() {
        return this.$store.state.address.totalCount;
    }

    get currentPage() {
        return this.$store.state.address.currentPage;
    }

    columns = [{
        title: this.L('Message'),
        key: 'message',
        sortable: 'custom'
    }, {
        title: this.L('Type'),
        key: 'type'
    }]

    async mounted() {
        this.pageRequest.companyId = this.companyId;
        await this.getPage();
    }
}
