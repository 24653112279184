































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import Company from '../../../../store/entities/companycontext/contact'
import Address from '../address/address.vue'
import CompanyForm from './form-company.vue'
import CompanyRelationship from "../companyrelationship/companyrelationship.vue";
import CompanyContact from "@/views/setting/companycontext/companycontact/CompanyContact.vue";
import CompanyAction from "@/views/setting/companycontext/companyaction/companyaction.vue";
import CompanyInstruction from "@/views/setting/companycontext/companyinstruction/companyinstruction.vue";

@Component({
    components: {Address, CompanyForm, CompanyRelationship, CompanyContact, CompanyAction, CompanyInstruction}
})
export default class EditCompany extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    companyId: number;
    private company: Company = new Company();

    async created() {
        this.companyId = parseInt(this.$route.params["companyId"]);
    }

    async mounted() {
        this.$store.commit('company/edit', await this.$store.dispatch({
            type: 'company/get',
            data: this.companyId
        }));
        this.company = this.$store.state.company.editCompany;
    }

    save() {
        let companyForm = ((this.$refs.form as any).$refs.companyForm as any);
        companyForm.validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: 'company/update',
                    data: (this.$refs.form as any).getCompany() as Company
                });
                companyForm.resetFields();
                this.$router.back();
            }
        })
    }

    cancel() {
        ((this.$refs.form as any).$refs.companyForm as any).resetFields();
        this.$emit('input', false);
        this.$router.back();
    }
}
